<template>
  <v-container
    id="goal"
    tag="section"
    class="pa-0"
    fluid
    fill-height
  >
    <!-- <v-img
      :src="require(`@/assets/Package-1600x350px/${propValue.img}`)"
      max-height="100%"
      max-width="100%"
    ></v-img> -->
    <v-container fluid grid-list-sm class="pl-9">
      <v-img
        :src="require(`@/assets/Package-1600x350px/1.2.1600x611-px.jpg`)"
        max-height="100%"
        max-width="100%"
      ></v-img>
      <span v-show="RegisterShow">
        <!-- <Register receiveVar="N" :productSkey="propValue.skey" absolute right/> -->
        <Register receiveVar="N" absolute right/>
      </span>
      <v-row>
        <v-col cols="12">
          <v-card flat
              color="#F8F9F9"
              >
            <v-card-text align="start">
              <p>
                <span class="display-4">{{ propValue.product_name }}</span>
              </p>
            </v-card-text>
          </v-card>
          <v-card
            v-show="propValue.featureHeader"
            flat
            color="#F8F9F9"
            >
            <v-card-text align="start">
              <v-row>
                <v-col cols="12"><span class="display-3"><strong>คุณสมบัติผู้สมัคร</strong></span></v-col>
                <v-col cols="6">
                  <p>
                    <span class="display-3 text-decoration-underline">
                      <strong>บุคคลธรรมดา</strong>
                    </span> 
                  </p>
                  <p>
                    <span class="display-2">{{ propValue.feature }}</span> 
                  </p>
                  <p>
                    <span class="display-2">{{ propValue.feature2 }}</span> 
                  </p>
                  <p>
                    <!-- <span class="display-2">● ไม่เป็นบุคคล้มละลาย</span> -->
                    <span class="display-2">{{ propValue.feature3 }}</span> 
                  </p>
                  <p>
                    <!-- <span class="display-2">● ไม่เป็นผู้ถูกพิทักษ์ทรัพย์เด็ดขาด</span> -->
                    <span class="display-2">{{ propValue.feature4 }}</span> 
                  </p>
                </v-col>
                <v-col cols="6">
                  <p>
                    <span class="display-3 text-decoration-underline"><strong>
                      นิติบุคคล
                    </strong></span> 
                  </p>
                  <p>
                    <!-- <span class="display-2">● เป็นนิติบุคคลที่จดทะเบียนตามกฎหมายไทย</span>  -->
                    <span class="display-2">{{ propValue.featureCompany }}</span>
                  </p>
                  <p>
                    <!-- <span class="display-2">● ดำเนินธุรกิจมาแล้วไม่ต่ำกว่า 1 ปีและส่วนทุนเป็นบวก</span> -->
                    <span class="display-2">{{ propValue.featureCompany2 }}</span>
                  </p>
                  <p>
                    <!-- <span class="display-2">● ไม่เป็นนิติบุคคลล้มละลาย</span> -->
                    <span class="display-2">{{ propValue.featureCompany3 }}</span>
                  </p>
                  <p>
                    <!-- <span class="display-2">● ไม่เป็นนิติบุคคลที่โดนพิทักษ์ทรัพทย์เด็ดขาด</span> -->
                    <span class="display-2">{{ propValue.featureCompany4 }}</span>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <v-card
            v-show="propValue.objectiveHeader"
            flat 
            max-width="100%" 
            color="#F8F9F9"
            >
            <v-card-text align="start">
              <p>
                <span class="display-3"><strong>{{ propValue.objectiveHeader }}</strong></span> 
              </p>
              <p>
                <!-- <span class="display-2">● เพื่อเสริมสภาพคล่องของบุคคลธรรมดา และนิติบุคคล</span>  -->
                <span class="display-2">{{ propValue.objective }}</span> 
              </p>
              <p>
                <!-- <span class="display-2">● เพื่อการลงทุนในกิจการ</span> --> 
                <span class="display-2">{{ propValue.objective2 }}</span> 
              </p>
              <p>
                <!-- <span class="display-2">● เพื่อไถ่ถอน (Refinance)</span> -->
                <span class="display-2">{{ propValue.objectiv3 }}</span> 
              </p>
              <p>
                <!-- <span class="display-2">● เพื่อวัตถุประสงค์อื่นๆ</span> --> 
                <span class="display-2">{{ propValue.objective4 }}</span> 
              </p>
              <!-- <p>
                <span class="display-2">{{ propValue.objective }}</span> 
              </p>  -->       
            </v-card-text>
          </v-card>

          <v-card
            v-show="propValue.fee"
            flat
            color="#F8F9F9"
            >
            <v-card-text align="start">
              <p>
                <!-- <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.fee') }}</strong></span> -->  <!-- Golf commentds -->
                <span class="display-3"><strong>{{ propValue.FeeHeader }}</strong></span><!-- Golf add 2022-12-30 -->
              </p>
              <p>
                <span class="display-2">{{ propValue.fee }}</span> 
              </p>        
            </v-card-text>
          </v-card>
          <!-- <v-card
            v-show="propValue.docHeader"
            flat
            color="#F8F9F9"
            >
            <v-card-text align="start">
              <p>
                <span class="display-3"><strong>{{ propValue.docHeader }}</strong></span> 
              </p>
              </v-card-text>
              <v-card-text>
              <p>
                <span class="display-2">{{ propValue.doc }}</span> 
              </p>
              <p>
                <span class="display-2">{{ propValue.doc2 }}</span> 
              </p>
            </v-card-text>
          </v-card> -->
          <!-- /* Golf 2022-9-1 */ -->
          <v-card flat class="pl-0"
            color="#F8F9F9"
            >
            <!-- <v-card-title primary-title class="justify-center display-3"> -->
            <v-card-title class="display-3">
              <strong>เอกสารการสมัครสำหรับบุคคลธรรมดา</strong>
            </v-card-title>
            <v-card-text align="start">
              <!-- <v-container> -->
                <!-- <v-row align="center" justify="center"> -->
                <v-row align="start">
                  <document-feed-card
                    v-for="(doc) in paginatedDocument"
                    :key="doc.document_skey"
                    :size="2"
                    :value="doc"
                    v-show="doc.document_skey!==4&&doc.document_skey!==6&&doc.document_skey!==7"
                  />
                </v-row>
              <!-- </v-container> -->
            </v-card-text>
          </v-card>
          <v-card flat
            color="#F8F9F9"
            >
            <!-- <v-card-title primary-title class="justify-center display-3"> -->
            <v-card-title class="display-3">
              <strong>เอกสารการสมัครสำหรับนิติบุคคล</strong>
            </v-card-title>
            <v-card-text align="start">
              <!-- <v-container> -->
                <!-- <v-row align="center" justify="center"> -->
                <v-row align="start">
                  <document-feed-card
                    v-for="(doc) in paginatedDocument"
                    :key="doc.document_skey"
                    :size="2"
                    :value="doc"
                    v-show="doc.document_skey!==5"
                  />
                </v-row>
              <!-- </v-container> -->
            </v-card-text>
          </v-card>
          <!-- /* Golf 2022-9-1 */ -->
          <!-- <v-card
            v-show="propValue.detailHeader1"
            flat
            color="#F8F9F9">
            <v-card-text align="start"> 
              <p>
                <span class="display-3"><strong>{{ propValue.detailHeader1 }}</strong></span> 
              </p>
              <p>
                <span class="display-2">{{ propValue.detail1 }}</span> 
              </p>
            </v-card-text>
          </v-card> -->
            <v-card
              v-show="propValue.detailHeader2"
              flat
              color="#F8F9F9"
              >
            <v-card-text align="start">    
              <p>
                <span class="display-3"><strong>{{ propValue.detailHeader2 }}</strong></span> 
              </p>
              <p>
                <span class="display-2">{{ propValue.detail2 }}</span> 
              </p>
            </v-card-text>
          </v-card>
          <v-card
            v-show="propValue.detailHeader3"
            flat
            color="#F8F9F9"
            >
            <v-card-text align="start">
              <p>
                <span class="display-3"><strong>{{ propValue.detailHeader3 }}</strong></span> 
              </p>
              <p>
                <span class="display-2">{{ propValue.detail3 }}</span> 
              </p>
              <p>
                <span class="display-2">{{ propValue.detail3_2 }}</span> 
              </p>
            </v-card-text>
          </v-card>
          <v-card
            v-show="propValue.detailHeader4"
            flat
            color="#F8F9F9"
            >
            <v-card-text align="start">    
              <p>
                <span class="display-3"><strong>{{ propValue.detailHeader4 }}</strong></span> 
              </p>
              </v-card-text>
              <v-card-text> 
              <p>
                <span class="display-2">{{ propValue.detail4 }}</span> 
              </p>
              <!-- <p>
                <span class="display-2">{{ propValue.detail4_2 }}</span> 
              </p> -->
            </v-card-text>
          </v-card>
          <v-card
            v-show="propValue.installmentHeader"
            flat
            color="#F8F9F9"
            >
            <v-card-text align="start">    
              <p>
                <span class="display-3"><strong>{{ propValue.installmentHeader }}</strong></span> 
              </p>
              </v-card-text>
              <v-card-text> 
              <p>
                <span class="display-2">{{ propValue.installmentDetail }}</span> 
              </p>
            </v-card-text>
          </v-card>
          <v-card 
            v-show="propValue.detailHeader5"
            flat
            color="#F8F9F9"
            >
            <v-card-text align="start">        
              <p>
                <span class="display-3"><strong>{{ propValue.detailHeader5 }}</strong></span> 
              </p>
              <p>
                <span class="display-2">{{ propValue.detail5 }}</span> 
              </p>
            </v-card-text>
          </v-card>
          <!-- <v-card
            flat
            v-show="propValue.contactHeader"
            color="#F8F9F9"
            >
            <p>
              <span class="display-3"><strong>{{ propValue.contactHeader }}</strong></span> 
            </p>
            <v-card-text align="start">
              <v-container>
                <v-row align="start">
                  <v-col align="center"
                    cols="12"
                    class="pl-2 pr-0"
                    md="3"
                  >
                    <v-card class="mx-auto" flat outlined color="transparent"
                    >
                      <v-img
                        :src="require(`@/assets/icon-button/contract/line_logo_icon_186887.png`)"
                        width="50%"
                        height="50%"
                      ></v-img>
                      <v-card-text align="center">
                        <span class="display-2">{{propValue.contact1}}</span>
                        <p><span class="display-1">
                          <a href="https://line.me/R/ti/p/@081glqvd?from=page" target="_blank">@081glqvd
                          </a>
                          </span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col align="center"
                    cols="12"
                    class="pl-2 pr-0"
                    md="3"
                  >
                    <v-card class="mx-auto" flat outlined color="transparent"
                    >
                      <v-img
                        :src="require(`@/assets/icon-button/contract/facebook.png`)"
                        width="50%"
                        height="50%"
                      ></v-img>
                      <v-card-text align="center">
                        <span class="display-2">{{propValue.contact2}}</span>
                        <p><span class="display-1">
                          <a href="https://facebook.com/GSBSociety" target="_blank">https://facebook.com/MEETEE
                          </a>
                          </span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col align="center"
                    cols="12"
                    class="pl-2 pr-0"
                    md="3"
                  >
                    <v-card class="mx-auto" flat outlined color="transparent"
                    >
                      <v-img
                        :src="require(`@/assets/icon-button/contract/telephone.jpg`)"
                        width="50%"
                        height="50%"
                      ></v-img>
                      <v-card-text align="center">
                        <span class="display-2">{{propValue.contact3}}</span>
                        <p><span class="display-1">99-999-9999</span></p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col align="center"
                    cols="12"
                    class="pl-2 pr-0"
                    md="3"
                  >
                    <v-card class="mx-auto" flat outlined color="transparent"
                    >
                      <v-img
                        :src="require(`@/assets/icon-button/contract/location_icon.png`)"
                        width="50%"
                        height="50%"
                      ></v-img>
                      <v-card-text align="center">
                        <router-link class="nav-link" to="/BranchGSB" target="_blank">
                          <span class="display-2">{{propValue.contact5}}</span>
                        </router-link>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card> -->
        </v-col>
      </v-row>
      <subscribe ShowMainPage="N"/>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: 'FeedCard',
    props: {
      size: {
        type: Number,
        required: false,
      },
      propValue: {
        type: Object,
        default: () => ({}),
      },
    },
    /* Golf 2022-9-1 */
    mounted () {
      window.scrollTo(0, 0)
    },
    data: () => ({
      utils: require('@/utils'),
      layoutDocument: [2, 2, 2, 2, 2],
      page: 1,
      document: require('@/data/document.json'),
      /* layoutDocument: [2, 2, 2, 2, 2],
      page: 1,
      Contract: require('@/data/Contract.json'), */
      RegisterShow: false,
    }),
    /* Golf 2022-9-1 */
    components: {
      Register: () => import('@/components/home/Register'),
      DocumentFeedCard: () => import('@/components/DocumentFeedCard'), //  /* Golf 2022-9-1 */
      Subscribe: () => import('@/components/home/Subscribe'),
    },
    created: function () {
      // this.$swal({type: 'warning', html: 'propValue ' + this.propValue.skey})
      if (!this.propValue.skey) {
        this.getProductList()
      }
      this.utils.UtilsAddVisitCountMenu(this.currentRouteName, this.propValue.skey) /* 1 visit count menu */
      this.getGlobalRegisterShow()
    },
    computed: {
      paginatedDocument () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.document.slice(start, stop)
      },
      currentRouteName () { /* 3 visit count menu */
        return this.$route.name
      },
    },
    methods: {
      selectLanguage (language) {
        console.log(language, 'language componnet Register')
        this.getProductList(language)
      },
      async getProductList (language) {
        console.log(language, 'language')
        // this.$swal({type: 'warning', html: 'language_skey ' + language})
        var lang = 1
        if (language === 'en') {
          lang = 2
        } else {
          lang = 1
        }
        language = 'th'
        await this.axios.post(process.env.VUE_APP_API + '/product/retrieveProductList' + process.env.VUE_APP_DATABASE_TYPE, {
          lang: language,
        })
          .then(response => {
            this.propValue = response.data.data[0]
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      getGlobalRegisterShow () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalRegisterShow', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              if (response.data.data === 'Y' || response.data.data === 'y') {
                this.RegisterShow = true
              } else {
                this.RegisterShow = false
              }
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>

<style scoped>
.v-image__image {
  transition: .3s linear;
}
/* .hero {
  background: url('../assets/yourImage.jpg');
  background-size: cover;
  height: 100vh;
} */
/* background: url('http://www.birds.com/wp-content/uploads/home/bird4.jpg'); */
.bg-custom {
  background: url('../assets/articles/1.2.1600x350.jpg');
  background-size: cover;
}
/* .bg-custom {
  background: url('../assets/bg_custom_height350px.jpg');
  background-repeat: repeat-x;
} */
</style>
